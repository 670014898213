export const useMaasCatalogList = async () => {
  const { apiInstance } = useShopwareContext();

  return await useLazyAsyncData(
    "maasCatalogList",
    async () => {
      const maasCatalog = await apiInstance.invoke.get("/store-api/maas-catalog-list");

      return maasCatalog.data;
    },
    {
      immediate: true,
    }
  );
};
